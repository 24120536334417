import { AdminRole } from '@/generated/typing';
import { Feature, RolesMap, Section } from './user-access';

const userAccess: RolesMap = {
    [AdminRole.ClaimsLeader]: {
        [Section.ALL_SECTIONS]: true,
        [Feature.ALL_FEATURES]: true,
    },
    [AdminRole.ClaimsManager]: {
        [Section.DUPLICATE_CLAIM_FIELDS]: false,
        [Feature.UPDATE_CUSTOMER]: true,
        [Feature.UPDATE_BENEFICIARIES]: true,
        [Feature.GENERATE_MEMBERSHIP_AGREEMENT]: true,
        [Feature.SEND_DOCUMENT]: true,
        [Feature.UPDATE_SUBSCRIPTION_PAYMENT_METHOD]: true,
        [Feature.UPDATE_SUBSCRIPTION_SERVICE_FIELDS]: true,
        [Section.CHILDREN]: true,
        [Section.ACCOUNT]: true,
        [Section.ACCOUNT_UPDATE]: true,
        [Section.ACCOUNT_SETTINGS]: true,
        [Section.ADMIN]: true,
        [Section.API]: true,
        [Section.ADMINISTRATION]: true,
        [Section.BILLING_INFORMATIONS]: true,
        [Section.CLAIMS]: true,
        [Section.CLIENTS]: true,
        [Section.COMPENSATION]: true,
        [Section.COMPANIES]: true,
        [Section.COMPANY]: true,
        [Section.CONTRACTS]: true,
        [Section.CONTRACTS_UPDATE]: true,
        [Section.CONTRACTS_MAIL_TEMPLATE_UPDATE]: true,
        [Section.CUSTOMER_EXTERNAL_ID]: true,
        [Section.CUSTOMER_GDA]: true,
        [Section.WEBSITES_MAIL_TEMPLATE_UPDATE]: true,
        [Section.CONTRACTS_DOCUMENT_TEMPLATES]: true,
        [Section.DASHBOARD]: true,
        [Section.DATA_PROVIDERS]: true,
        [Section.IMPORT_HISTORY]: true,
        [Section.STORE_INVOICES]: true,
        [Section.DOCUMENT_TEMPLATES]: true,
        [Section.MAIL_TEMPLATES]: true,
        [Section.PAYMENT_METHODS]: true,
        [Section.PRODUCTS]: true,
        [Section.PRODUCT_CONFIGURATION]: true,
        [Section.PRODUCTS_CONFIGURATION]: true,
        [Section.PRODUCTS_STORES]: true,
        [Section.PRODUCTS_COVERAGE]: true,
        [Section.PRODUCTS_CLAIMS]: false,
        [Section.PROVIDERS]: true,
        [Section.SALES_CHANNELS]: true,
        [Section.SERVICES]: true,
        [Section.SERVICES_ACCOUNTS]: true,
        [Section.STORE]: true,
        [Section.STORES]: true,
        [Section.SUBSCRIBE]: true,
        [Section.SUBSCRIPTIONS]: true,
        [Section.SUBSCRIPTION_EXTERNAL_ID]: true,
        [Section.SUBSCRIPTION_PAYMENT_MEAN]: true,
        [Section.DEALERS]: true,
        [Section.ADMINS]: false,
        [Section.WEBSITES]: true,
        [Section.UPDATE_SUBSCRIPTION_CONTRACT]: true,
        [Section.PROVIDER_SUBSCRIPTIONS]: true,
        [Section.SUBSCRIPTION_PROCEDURES]: true,
        [Feature.DELETE_CLAIM]: false,
        [Section.CLAIM_PROPERTY_MAPPINGS]: false,
    },
};

export const adminHasAccessTo = (
    role: AdminRole,
    item: Section | Feature,
    specificCondition: boolean = true,
): boolean => {
    if (specificCondition !== undefined && !specificCondition) {
        return false;
    }

    if (role === AdminRole.SuperAdmin) {
        return true;
    }

    if (userAccess[role]) {
        if (
            Object.values(Section).includes(item as Section) &&
            userAccess[role]?.[Section.ALL_SECTIONS]
        ) {
            return true;
        }

        if (
            Object.values(Feature).includes(item as Feature) &&
            userAccess[role]?.[Feature.ALL_FEATURES]
        ) {
            return true;
        }
    }

    return !!userAccess[role]?.[item];
};
